@mixin profiler-target-form-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );
  $primary-palette: map-get($theme, primary);
  $warn-palette: map-get($theme, warn);
  $primary: mat-color($primary-palette);
  $warn: mat-color($warn-palette);

  .target-form-dialog {
    .my-list-chip {
      background-color: #d8e1e4 !important;
    }

    .invalid-value {
      background-color: red !important;
      color: $primary !important;
    }

    .profiler-target-form-header {
      color: rgba(51, 50, 56, 0.84);
    }

    .profiler-target-form-up-separation {
      border-bottom: 1px solid rgba(6, 15, 22, 0.3);
    }

    .profiler-target-form-down-separation {
      border-bottom: 1px solid rgba(6, 15, 22, 0.3);
      box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.38);
    }

    .profiler-target-form-input-group {
      .profiler-target-form-input,
      .profiler-target-form-select,
      .profiler-create-case-textarea,
      .profiler-target-form-countries-input {
        border: solid 1px #c3c3c3 !important;
      }

      .profiler-target-form-label {
        color: rgba(35, 35, 39, 0.84);
        .warn-text {
          color: $warn;
        }
      }
    }

    .target-form {
      .disabled {
        background-color: lightgray !important;
      }
    }

    .profiler-target-form-cancel {
      border: solid 2px #d3d3d3;
      background-color: $primary;
      color: rgba(139, 139, 139, 0.84);
    }

    .profiler-target-form-save {
      border: solid 1px #ebebeb;
      background-color: $profiler-accent;
      color: $primary;
    }

    .edit-badge {
      background: grey;
    }
  }

  .create-case-container {
    .create-case-checkbox {
      .mat-checkbox-frame {
        border-color: #2c7cff;
      }
      &.mat-checkbox-checked.mat-accent {
        .mat-checkbox-background {
          background-color: transparent;
          .mat-checkbox-checkmark-path {
            stroke: #2c7cff !important;
          }
        }
      }
    }
  }
}
