@mixin queries-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .query-tabs {
    .mat-card-title {
      color: $font1;
    }

    .new-query-card {
      box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
      background-color: $primary;
    }

    .btn-cancel {
      color: $font1;
      border: 1px solid #dcdcdc;
    }

    .btn-green {
      background-color: $accent;
      color: $primary;
    }
  }
}
