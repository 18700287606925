@mixin profiler-target-list-item-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-secondary: mat-color(
    $custom-colors,
    profiler-secondary
  );
  $secondary: mat-color($custom-colors, secondary);

  .profiler-target-item {
    background: linear-gradient(
      180deg,
      #f5f8f8 45%,
      #fffffe 0%
    );
    border: 1px solid #d8e1e4;
    &:hover {
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.15);
    }
    &.active {
      .avatar-content,
      .img-data {
        position: relative;
        &:before {
          color: green;
          background-color: #fff;
          box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .img-data {
      .target-photo {
        background-color: #f6f7f9;
      }
    }

    .avatar-content {
      color: $secondary !important;
      background-color: #f6f7f9 !important;
    }

    .target-name {
      color: $profiler-font;
    }

    .target-telno {
      color: $profiler-font;
    }
  }

  .current-target-item {
    .avatar-content {
      color: $secondary !important;
    }
  }

  .case-view-target-item {
    border: solid 1px $profiler-secondary;
  }
}
