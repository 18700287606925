@mixin profiler-social-media-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-second-font: mat-color(
    $custom-colors,
    profiler-second-font
  );
  $profiler-secondary: mat-color(
    $custom-colors,
    profiler-secondary
  );
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );

  .profiler-social-media {
    background-color: $primary;

    .social-no-data {
      color: $profiler-second-font;
    }

    .new-platform-select {
      border: 1px solid #e9eeef;
      background-color: $primary;
    }

    .new-platform-field {
      border: 1px solid #e9eeef;
    }

    .target-social-link {
      color: $profiler-accent;
      background-color: transparent;
    }

    .profiler-social-media-target-url {
      color: $profiler-accent;
      background-color: $primary;
    }

    .profiler-social-media-url {
      color: $profiler-accent;
    }
  }
}
