@mixin profiler-map-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $profiler-secondary: mat-color(
    $custom-colors,
    profiler-secondary
  );

  ::ng-deep {
    .profiler-map-menu-options-dropdown {
      .map-more-options-btn {
        color: $profiler-font;

        &.disabled {
          color: $profiler-secondary;
          pointer-events: none;
        }
      }

      .selected-map-option {
        background-color: $primary;
        border: 1px solid $profiler-secondary;
        color: #2a2a2a;
      }
    }
  }
}
