@mixin case-common-faces-theme($theme) {

  .case-blocks {
    background-color: #fff;
    &.case-faces {
      span.img-wrp {
        .case-common-faces-badge {
          background-color: #333;
          color: white;
        }
      }
    }
  
    .block-header {
      background-color: #d8e1e4;
    }
  }
  
  .accordion-row {
    border-bottom: 1px solid #e3e0de;
  }
}
