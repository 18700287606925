// @import "application.scss";
/* You can add global styles to this file, and also import other style files */
@import "~@busacca/ng-pick-datetime/assets/style/picker.min.css";
@import "~nouislider/dist/nouislider.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-toastr/toastr";
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./theme.scss";
// TODO: remove this file from global styles and load it only when is used.
@import "@trg-ui/link-analysis/map/leaflet.css";

/* You can add global styles to this file, and also import other style files */
@import "styles/variables";
@import "styles/dropzone";
@import "styles/buttons";
@import "styles/dialogs";
@import "styles/forms";
@import "styles/tables";
@import "styles/tooltip";
@import "styles/popups";
@import "styles/helpers";
@import "styles/animation";
@import "assets/font/style";

// TODO: please move default css settings in application.scss and add themes (component-themes.scss)
// ---------- Default CSS Starts ----------
@import "~@angular/cdk/overlay-prebuilt.css";

.containerFixed {
  max-width: 1460px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
.containerFixed.full-width {
  max-width: 100%;
  padding: 0px;
}
app-photo-carousel-modal,
app-tactical-dashboard,
app-case-dashboard,
app-dashboard,
app-admin,
app-user-options,
app-profiler-case-item,
app-progress-loader,
app-case-overview,
app-search-intel,
app-profiler-case-list,
app-profiler-target-list,
app-targets-dashboard,
app-notification-item,
app-profiler-overview,
app-case-relations,
app-ad-ids-analyze-location-history,
app-ad-ids-map,
app-ad-ids,
app-discovery-geo-zone-form,
app-target-modal,
app-group-details-dialog,
app-feed-item-detail-view-dialog,
app-ad-ids-table-header,
app-ad-ids-result-table,
app-call-log-dialog,
app-intel-loader,
app-search-intel-navbar,
app-queue,
app-search-filters,
app-search-history,
app-search-intel-dashboard,
app-search-result-dashboar,
app-search-results,
app-search-intel-navbar,
app-intel-loader,
app-cl-map-header {
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
    vertical-align: baseline;
    // color: #3c4144;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
  }

  input {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    outline: none;
    appearance: none;
    width: 100%;
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 5px;
  }
}

// Default End

.cdk-overlay-pane {
  &.profiler-media-dialog,
  &.instant-media-dialog,
  &.profiler-common-people-dialog {
    // TODO: remove later for modal popup
    max-width: 800px !important;
    width: 100%;
    height: 600px;
    line-height: 0;

    .mat-dialog-container {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.instant-media-dialog {
    height: auto;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

app-feed,
app-call-log-dashboard,
app-discover-persons,
app-profiler-overview,
app-link-analysis,
app-link-analysis-graph,
app-profiler-radical-monitoring {
  width: 100%;
}

// ---------- Default CSS Ends ----------

.discovery-history-menu {
  &.mat-menu-panel {
    max-width: inherit;
  }

  .mat-menu-content {
    padding: 0px !important;
  }
}

// Targets card
.case-targets-wrapper {
  .selectable-wrp {
    width: 18%;
    border-radius: 5px;
  }

  .select-target-bottom-row {
    padding: 5px;
  }

  .selectable-target .target-photo {
    border-radius: 50%;
    width: 100%;
    margin: 10px 0;
  }
}

.start-xs {
  justify-content: flex-start;
  text-align: start
}

.center-xs {
  justify-content: center;
  text-align: center
}

.end-xs {
  justify-content: flex-end;
  text-align: end
}

.top-xs {
  align-items: flex-start
}

.middle-xs {
  align-items: center
}

.bottom-xs {
  align-items: flex-end
}

.around-xs {
  justify-content: space-around
}

.between-xs {
  justify-content: space-between
}

.first-xs {
  order: -1
}

.last-xs {
  order: 1
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}