@mixin profiler-sidenav-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-secondary: mat-color(
    $custom-colors,
    profiler-secondary
  );
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );

  .tabs-section {
    .tab {
      color: $profiler-font;
    }

    .tab:hover {
      background-color: #f5f8f8;
    }

    .selected {
      background-color: #f3f5f7;
      border-left: 4px solid $profiler-accent !important;
      padding-left: 18px !important;
    }
  }
}
