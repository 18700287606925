@mixin profiler-radical-monitoring-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $profiler-second-font: mat-color(
    $custom-colors,
    profiler-second-font
  );
  $profiler-secondary: mat-color(
    $custom-colors,
    profiler-secondary
  );

  $primary-item-bg: #d8e1e4;
  $disabled-input-background: #ebebeb;
  $disabled-input-border-color: #dddfe2;
  $live-blue: #008df6;
  $font-dark: #686868;
  $font-darker: #3c4144;

  app-radical-monitoring-stats {
    .radical-monitoring-stats {
      background: $primary;
      .statistic {
        .title,
        .value {
          color: #424852;
        }
      }
    }
  }
  app-radical-monitoring-controls {
    mat-chip-list {
      .mat-chip-list-wrapper {
        background: $primary;
        border: solid 1px $profiler-secondary;
      }
      &.mat-chip-list-disabled {
        .mat-chip-list-wrapper {
          background: $disabled-input-background;
          border: solid 1px $disabled-input-border-color;
        }
      }
      .input-icon {
        color: #90949c;
      }
      input {
        color: $profiler-second-font;
      }
      mat-chip {
        color: #686868 !important;
        background-color: #d8e1e4 !important;
        &.keyword-list-child {
          background-color: #ffefc4 !important;
        }
      }
    }
    mat-select {
      background-color: $primary !important;
      border: solid 1px $profiler-secondary !important;
      color: $profiler-second-font !important;

      .mat-select-arrow {
        color: $profiler-secondary;
      }
      &.mat-select-disabled {
        background-color: $disabled-input-background !important;
        border: solid 1px $disabled-input-border-color !important;
        .mat-select-placeholder {
          color: #2a2a2a;
        }
        mat-select-trigger {
          color: #2a2a2a;
        }
      }
    }
    mat-slide-toggle {
      &.large {
        .mat-slide-toggle-bar {
          &:before {
            color: transparent;
          }
          &:after {
            color: #797979;
          }
        }
        .mat-slide-toggle-thumb {
          background-color: white !important;
          box-shadow: none !important;
        }
        &.mat-checked {
          .mat-slide-toggle-bar {
            background-color: #008df6 !important;
            &:before {
              color: white;
            }
            &:after {
              color: transparent;
            }
          }
        }
      }
    }
  }

  app-radical-monitoring-session {
    mat-accordion {
      mat-expansion-panel {
        .keywords-details {
          background: $primary-item-bg !important;
          color: $font-dark !important;
        }
        .keyword-lists-details {
          mat-chip {
            background-color: $primary-item-bg !important;
            color: $font-dark !important;
          }
        }
      }
    }
    .session-record-menu {
      color: $font-dark !important;
    }
  }

  app-radical-monitoring-keyword-lists {
    mat-form-field {
      background: #d8e1e4;
      .mat-form-field-infix {
        border-top: none !important;
      }
      input {
        &,
        &::placeholder {
          color: $font-darker !important;
        }
      }
    }
    .mat-icon-button {
      &.list-action {
        color: #464646;
        &-delete {
          mat-icon {
            color: #ff908a;
          }
        }
      }
    }
  }
  app-radical-monitoring-keyword-lists,
  app-radical-monitoring-session {
    .section {
      &-head {
        background-color: #d8e1e4;
        &-action {
          color: #414242;
        }
      }
      &-content {
        background-color: $primary;
        table {
          tr {
            &.highlight {
              animation: blink-highlight 1200ms 1 ease-in; /* IE 10+, Fx 29+ */
            }
          }
        }
      }
      mat-accordion {
        mat-expansion-panel {
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }
  }

  .error-box {
    & * {
      color: $profiler-second-font;
    }
  }

  .filter-indicators {
    &:before {
      border-bottom: 4px solid #333333;
    }
    &:after {
      border-top: 4px solid #333333;
    }
  }

  .empty-list {
    color: $font-darker;
  }
  mark {
    background-color: #ffff92;
  }
  .point {
    background-color: $disabled-input-background;
    &.live {
      background-color: $live-blue;
    }
  }

  @keyframes blink-highlight {
    from {
      background-color: #fdf5d5;
    }
    to {
      background-color: inherit;
    }
  }
}
