@mixin profiler-feed-item-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-second-font: mat-color(
    $custom-colors,
    profiler-second-font
  );
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $profiler-secondary: mat-color(
    $custom-colors,
    profiler-secondary
  );
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );

  .notes-input {
    background-color: #dfdfdf;
  }

  .feed-item-timeline-container {
    .feed-event-timeline-date {
      color: $profiler-font;
      border-right: 2px solid $profiler-secondary;
    }

    .feed-event-timeline {
      border: solid 2px $profiler-secondary;
      background-color: $profiler-font;
    }
  }

  .note {
    background-color: #ecedf1;
  }

  .profiler-feed-item-title {
    color: #4a6da7;
  }

  .profiler-feed-item-subtitle {
    color: #90949c;
    padding: 5px 0px;
  }

  .feed-item-container {
    border: solid 1px #dddfe2;
    background-color: $primary;
  }

  .highlight-feed-container {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.22);
    border: solid 1px $profiler-accent;
  }

  .custom-feed-item-panel {
    border-bottom: solid 1px #dddfe2;
    border-left: solid 1px #dddfe2;
    border-right: solid 1px #dddfe2;
    background-color: $primary;
    .custom-feed-item-panel-header {
      color: $secondary;
    }
  }

  .profiler-feed-description {
    color: $profiler-second-font;
  }

  .feed-item-post-content {
    color: $secondary;
  }

  .see-more-less-text {
    color: $profiler-accent;
  }
}
