@mixin profiler-about-section-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-second-font: mat-color(
    $custom-colors,
    profiler-second-font
  );
  $profiler-third-font: mat-color(
    $custom-colors,
    profiler-third-font
  );
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $section-header: mat-color(
    $custom-colors,
    section-header
  );
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );
  $light-pink: map-get($custom-colors, light-pink);
  $red: map-get($custom-colors, red);

  // Common header styling for profiler target sections
  .profiler-section-header {
    background-color: $section-header;
    .profiler-section-header-text {
      color: $profiler-third-font;
    }
  }

  .profiler-about {
    background-color: $primary;

    .profiler-about-even {
      background-color: $primary;
    }

    .profiler-about-odd {
      background-color: $primary;
    }

    .title-row {
      background-color: $section-header;
    }

    .placeholder-input {
      border: 1px solid rgb(130, 138, 139);
    }

    .select-new-field {
      border: 1px solid rgb(130, 138, 139);
      color: $profiler-second-font;
    }

    .values {
      color: $profiler-second-font;
      background-color: unset !important;
    }

    .email-values {
      color: $profiler-second-font;
    }

    .my-email-chip {
      background-color: $section-header !important;
    }

    .invalid-value {
      background-color: red !important;
      color: $primary !important;
    }

    .picker-value {
      color: $profiler-second-font;
    }

    .value-edit-mode {
      border: 1px solid rgb(130, 138, 139);
      background-color: $primary;
    }

    .labels {
      color: $profiler-second-font;
    }

    .view-text {
      color: $profiler-accent;
    }
    .chip {
      &.warning {
        background-color: $light-pink;
        .mat-icon {
          color: $red;
        }
      }
    }
  }
}
