@mixin case-item-card-theme($theme) {

  .case-item {
    background-color: white;
    border: 1px solid #d8e1e4;
    .case-img {
      background-color: #e1e1e1;
    }
  }
}
