// ---------- variables for CP ----------
// Blue Shades
$darkBlue: #204cd3;
$brightBlue: #396aff;
$mediumBlue: #a4baff;
$blue-100: #005cff;

// Gray Shades
$lightBlue: #040407;
$darkGray: #adafb2;
$mediumGray: #d7dade;
$lightGray: #ededed;
$gray-50: #f9f9f9;
$gray-100: #424852;
$gray-200: #040930;
$gray-settings-base: #404040;
