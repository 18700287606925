@mixin notification-item-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $green: mat-color($custom-colors, green);
  $font4: mat-color($custom-colors, font4);
  $background: mat-color($custom-colors, background-color);
  $secondary: mat-color($custom-colors, secondary);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .notification {
    background-color: $primary;
    color: #5d6a70;
    border-bottom: 1px solid #e2e2e2;

    .emergency-text {
      color: $font4;
    }

    .unread {
      color: $secondary;
    }

    .read {
      color: darkgray;
    }

    &-label{
      color: #3d3d44;
      span{
        color: #4d75a9;
      }
    }
    &-content{
      color: #5c5c5d;
    }
    .system-notification{
      .notification-action {
        background-color: $accent;
        color: $primary;
      }
    }
  }

  .notification:hover {
    background-color: $background;
  }

  .notification-new-query {
    color: $green !important;
  }

  .release-notes {
    color: $accent !important;
  }

  .notification-dismiss {
    color: #d44b05 !important;
  }

  // MOBILE
  .notification-mobile {
    .notification-text {
      color: #677379;
      border-bottom: 1px solid #d2d2d2;
    }
    .pin {
      background-color: #84daea;
      color: $primary;
    }
  }

  .notification-actions {
    .notification-action {
      background: #d8e1e4;
    }
  }
}
