@supports (-moz-appearance:none) {

  .custom-geo-marker-toolbox {
    top: -230px !important;
    left: -240px !important;
  }

  .owl-datepicker {
    position: relative !important;
  }


  .unique-filter {
    margin-left: 2vw;
  }

  .target-form {
    .mat-form-field-wrapper {
      padding-bottom: unset !important;
    }

    .inputs {
      margin-bottom: 5px !important;

      .user {
        .mat-form-field-infix {
          height: 4vh;
        }
      }
    }
  }
}
