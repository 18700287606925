@mixin profiler-radical-monitoring-record-dialog-theme(
  $theme
) {
  .radical-monitoring-record-view-dialog {
    .target-info {
      & .target-details {
        & .target-name {
          color: #4a6da7;
        }
        & .record-published-at {
          color: #90949c;
        }
      }
    }
    .radical-monitoring-record-details {
      &-content {
        .content-helper {
          color: #4a6da7;
        }
        .text-content {
          color: #414242;
        }
      }
    }
  }
}
