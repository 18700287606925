@mixin profiler-feed-note-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .profiler-feed-note-container {
    border: solid 1px #dddfe2;
    background-color: $primary;

    .profiler-feed-note-header {
      color: #4a6da7;
    }

    .profiler-feed-note-textarea {
      border: solid 1px #dddfe2;
      background-color: #f7f7f7;
    }

    .uploaded-image-text {
      color: $secondary;
    }

    .profiler-feed-note-details {
      color: #90949c;
    }

    .save-feed-note {
      border: solid 1px $profiler-accent;
      background-color: $profiler-accent;
      span {
        color: $primary;
      }
    }

    .cancel-feed-note {
      border: solid 1px #dddfe2;
      background-color: $primary;
      color: rgba(139, 139, 139, 0.84);
    }
  }
}
