@mixin searchIntel-result-dashboard-theme($theme) {

  .queue-wrp {
    background-color: #fff;
  }

  .search-intel-dashboard-loader {
    ::ng-deep {
      .mat-progress-spinner circle {
        stroke: #414242 !important;
      }
    }
  }
}
