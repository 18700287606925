@mixin profiler-viber-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .viber-im-card {
    background-color: $primary;
  }
}
