@mixin profiler-tinder-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .rinder-im-card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $primary;
  }
}
