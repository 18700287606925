@mixin profiler-target-note-form-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $custom-colors: map-get($theme, custom-colors);
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );

  .profiler-target-note-form {
    .target-note-form {
      .disabled {
        background-color: grey;
      }
    }

    .target-note-form-close-btn {
      color: rgba(51, 50, 56, 0.53);
    }

    .target-note-form-header {
      color: rgba(51, 50, 56, 0.84);
    }

    .target-note-form-actions {
      .cancel {
        border: solid 2px #d3d3d3;
        color: rgba(139, 139, 139, 0.84);
      }

      .ok {
        background-color: $profiler-accent;
        color: $primary;
      }
    }
  }
}
