@mixin add-case-dialog-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $custom-colors: map-get($theme, custom-colors);
  $profiler-accent: mat-color(
    $custom-colors,
    profiler-accent
  );

  .profiler-case-form-save {
    background-color: $profiler-accent;
    color: $primary;
  }
}
