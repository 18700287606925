.animate-scale {
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
  button.animate-slide-white, button.animate-slide-blue {
    position: relative;
    overflow: hidden;
    z-index: 0;
    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: white;
      position: absolute;
      left: 0;
      transform: translateX(-110%);
      transition: 0.5s;
      top: 0;
      z-index: -1;
    }
    &:hover {
      border: 1px solid #396aff;
      &:before {
        transform: translateX(0);
      }
      span {
        color: #396aff;
        z-index: 1;
        &.credits {
          border: 2px solid #396aff;
        }
      }
    }
  }
  button.animate-slide-blue {
    &:before {
      background-color: #396aff;
    }
    &:hover {
      color: #fff;
    }
  }