form {
  label {
    display: inline-block;
    margin-bottom: 0.5rem !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    letter-spacing: -0.09px;
    text-align: left;
    color: #414242;
  }

  .form-group {
    margin: 0 5px;
    margin-bottom: 1rem;

    .error {
      padding-left: 1px;
      font-size: 13px;
    }
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .form-text {
    display: block;
    margin-top: 0.25rem;
    color: #6c757d !important;
  }
}

/*  Mat Chip List*/
mat-chip-list {
  width: 100%;
  .mat-chip-list-wrapper {
    input.mat-input-element {
      padding: 0;
    }

    .mat-standard-chip {
      border-radius: 20px;
      background-color: #ededed;
    }
  }
}

.input-wrp {
  &.input-search {
    position: relative;
    input {
      padding-left: 20px;
    }
    mat-icon {
      position: absolute;
      background-color: #fff;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      margin: 0;
      padding: 0;
      cursor: pointer;
      &.close-icon {
        left: inherit;
        right: 3px;
      }
    }
  }
}

.input-style-round {
  border-radius: 5px;
  padding: 7px;
  border: 1px solid #d7dade;
}

.input-hint {
  font-size: 12px;
}

// Checkboxes Start
mat-checkbox {
  ::ng-deep {
    .mat-checkbox-inner-container {
      height: 12px;
      width: 12px;
    }

    .mat-checkbox-frame {
      border-width: 1px;
    }
  }

  &.round-checkbox {
    ::ng-deep {
      .mat-checkbox-frame,
      .mat-checkbox-background {
        background-color: #fff;
        border-radius: 50% !important;
      }

      .mat-checkbox-inner-container {
        position: absolute;
        right: 12px;
        top: 5px;
        z-index: 1;
      }
    }
  }
}

::ng-deep {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent
    .mat-checkbox-background {
    background-color: #396aff !important;
  }

  .mat-checkbox-checked
    .mat-checkbox-background
    .mat-checkbox-checkmark-path {
    stroke: #fafafa !important;
  }
}
// Ends

// For CP Starts --------------------
.input-box {
  width: 100%;
  position: relative;
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-flex {
    border: 1px solid #d7dade;
    border-radius: 5px;
    padding: 0 10px;
    .mat-form-field-infix {
      border: 0;
    }
  }
  .mat-form-field-underline {
    display: none;
  }
}
::ng-deep {
  .mat-form-field-should-float label {
    visibility: hidden;
  }
}

.field-group {
  margin: 15px 0;
  label {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .mat-form-field-should-float label {
    visibility: hidden;
  }
  .eye-icon {
    color: #9c9fa0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: 20px;
    cursor: pointer;
  }
}
// For CP Ends --------------------
